


/*Главное меню*/

const menu = document.querySelector('.main-menu'),
    burger = document.querySelector('.burger'),
    mobileBack = document.querySelector('.mobile-back'),
    overlay = document.querySelector('.overlay'),
    navitem = document.querySelector('.nav__list'),
    closebtn = document.querySelector('.close_btn');

const lockScroll = () => {
    document.body.classList.add('lock');
}

const unlockScroll = () => {
    document.body.classList.remove('lock');
}

/* const initialMenu = () => {
    document.querySelector('.nav__list--dropdown').classList.remove('transformation');
    document.querySelector('.nav').querySelector('.nav__list').classList.remove('transformation');
    scrollTop();
} */

const scrollTop = () => {
    menu.scrollTo({
        top: 100,
        behavior: 'smooth',
    });
}

burger.addEventListener('click', () => {
    menu.classList.add('open');
    overlay.classList.add('open');
    lockScroll();
    // initialMenu();
});

overlay.addEventListener('click', () => {
    menu.classList.remove('open');
    overlay.classList.remove('open');
    unlockScroll();
});
closebtn.addEventListener('click', () => {
    menu.classList.remove('open');
    overlay.classList.remove('open');
    unlockScroll();
});
navitem.addEventListener('click', () => {
    menu.classList.remove('open');
    overlay.classList.remove('open');
    unlockScroll();
});


/* menu.addEventListener('click', (e) => {
    if (e.target.classList.contains('nav__link--drop')) {
        e.preventDefault();
        e.target.closest('.nav__list').classList.add('transformation');
        e.target.closest('.nav__item').querySelector('.nav__list--dropdown').classList.add('transformation');
        scrollTop();
    }

    if (e.target.classList.contains('mobile-back__link')) {
        e.preventDefault();
        e.target.closest('.nav__list--dropdown').classList.remove('transformation');
        e.target.closest('.main-menu').querySelector('.nav__list').classList.remove('transformation');
        scrollTop();
    }

        if (e.target.classList.contains('nav__link') && !e.target.classList.contains('nav__link--drop')) {
            e.preventDefault();
            menu.classList.remove('open');
            overlay.classList.remove('open');
            unlockScroll();
        }
});
 */


/*Фиксируем меню*/

var element = document.getElementById('header');
window.addEventListener('scroll', function () {
    if (window.scrollY > 100) {
        element.classList.add("scrolled");
    } else {
        element.classList.remove("scrolled");
    }
});


var element2 = document.getElementById('mt-content');
window.addEventListener('scroll', function () {
    if (window.scrollY > 100) {
        element2.classList.add("mfixed");
    } else {
        element2.classList.remove("mfixed");
    }
});



/*Инициализируем тултипы*/

var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-tooltip="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
})


/*Отправка форм*/


const forms = document.querySelectorAll('form');

let flagCaptcha = false;

const errorLabelObj = {
    color: '#e90707',
    marginTop: '6px',
    fontSize: '14px',
    textAlign: 'left',
}


forms.forEach((form, i) => {
    form.insertAdjacentHTML('beforeend', `<div id="recaptcha-${i + 1}"></div>`);
})

// нужен именно var
var onloadCallback = function () {
    const KEY = '6Lc0F6whAAAAAI0yHeOIz3nr56TDrFZlHvkvN4sU';
    forms.forEach((form, i) => {
        grecaptcha.render(`recaptcha-${i + 1}`, {
            'sitekey': KEY,
            'callback': captchaCheck,
        })
    })
}

const captchaCheck = () => {
    flagCaptcha = true;
}

const sendForm = (event) => {

    const fileInput = document.querySelector('input[type="file"]');
    fileInput.addEventListener('change', (e) => {
        let files = e.currentTarget.files;
        console.log(files);

        if (files.length) {
            fileInput.closest('label').querySelector('span').textContent = files[0].name;
        } else {
            fileInput.closest('label').querySelector('span').textContent = 'Прикрепить файл';
        }

    });




    const formData = new FormData(event);
    fetch('mail/mail.php', {
        method: 'POST',
        body: formData
    })
        .then((data) => {
            if (data.status === 200) {

                $('.fade').delay(1700).fadeOut(300);
                $('#success').fadeIn(500).delay(1700).fadeOut(600);
            } else {
                console.log('Ошибка');
                //openModal(modalError);
            }
        })
        .catch(error => {
            console.log('Ошибка');
        });
}

const formHandler = (event) => {
    sendForm(event.target);
    event.target.reset();
}

// Валидация
if (document.querySelector('#requestme')) {
    const formQstValid = new JustValidate('#requestme', {
        errorFieldCssClass: 'invalid',
        errorLabelStyle: errorLabelObj,
    });
    formQstValid

        .addField('#requestme [type="tel"]', [{
            rule: 'required',
            value: true,
            errorMessage: 'Поле обязательно для заполнения',
        },
        {
            rule: 'minLength',
            value: 16,
            errorMessage: 'Введите телефон в формате +7(***) *** ** **'
        },
        ])
        .addField('#requestme [type="email"]', [{
            rule: 'required',
            value: true,
            errorMessage: 'Поле обязательно для заполнения'
        },
        {
            rule: 'email',
            value: true,
            errorMessage: 'Введите корректный Email',
        },
        ])

        .onSuccess((event) => {
            if (flagCaptcha) {
                formHandler(event);
                flagCaptcha = false;
            }
        });
}

if (document.querySelector('#buyme-modal')) {
    const formQstValid = new JustValidate('#buyme-modal', {
        errorFieldCssClass: 'invalid',
        errorLabelStyle: errorLabelObj,
    });
    formQstValid

        .addField('#buyme-modal [type="tel"]', [{
            rule: 'required',
            value: true,
            errorMessage: 'Поле обязательно для заполнения',
        },
        {
            rule: 'minLength',
            value: 16,
            errorMessage: 'Введите телефон в формате +7(***) *** ** **'
        },
        ])
        .addField('#buyme-modal [type="email"]', [{
            rule: 'required',
            value: true,
            errorMessage: 'Поле обязательно для заполнения'
        },
        {
            rule: 'email',
            value: true,
            errorMessage: 'Введите корректный Email',
        },
        ])

        .onSuccess((event) => {
            if (flagCaptcha) {
                formHandler(event);
                flagCaptcha = false;
            }
        });
}



if (document.querySelector('#buyme2-modal')) {
    const formQstValid = new JustValidate('#buyme2-modal', {
        errorFieldCssClass: 'invalid',
        errorLabelStyle: errorLabelObj,
    });
    formQstValid

        .addField('#buyme2-modal [type="tel"]', [{
            rule: 'required',
            value: true,
            errorMessage: 'Поле обязательно для заполнения',
        },
        {
            rule: 'minLength',
            value: 16,
            errorMessage: 'Введите телефон в формате +7(***) *** ** **'
        },
        ])
        .addField('#buyme2-modal [type="email"]', [{
            rule: 'required',
            value: true,
            errorMessage: 'Поле обязательно для заполнения'
        },
        {
            rule: 'email',
            value: true,
            errorMessage: 'Введите корректный Email',
        },
        ])

        .onSuccess((event) => {
            if (flagCaptcha) {
                formHandler(event);
                flagCaptcha = false;
            }
        });
}


if (document.querySelector('#callme-modal')) {
    const formQstValid = new JustValidate('#callme-modal', {
        errorFieldCssClass: 'invalid',
        errorLabelStyle: errorLabelObj,
    });
    formQstValid

        .addField('#callme-modal [type="tel"]', [{
            rule: 'required',
            value: true,
            errorMessage: 'Поле обязательно для заполнения',
        },
        {
            rule: 'minLength',
            value: 16,
            errorMessage: 'Введите телефон в формате +7(***) *** ** **'
        },
        ])


        .onSuccess((event) => {
            if (flagCaptcha) {
                formHandler(event);
                flagCaptcha = false;
            }
        });
}

if (document.querySelector('#askme-modal')) {
    const formQstValid = new JustValidate('#askme-modal', {
        errorFieldCssClass: 'invalid',
        errorLabelStyle: errorLabelObj,
    });
    formQstValid

 
        .addField('#askme-modal [type="email"]', [{
            rule: 'required',
            value: true,
            errorMessage: 'Поле обязательно для заполнения'
        },
        {
            rule: 'email',
            value: true,
            errorMessage: 'Введите корректный Email',
        },
        ])

        .onSuccess((event) => {
            if (flagCaptcha) {
                formHandler(event);
                flagCaptcha = false;
            }
        });
}





const maskedPhoneNumbers = () => {
    let phoneNumber = document.querySelectorAll('[type="tel"]');

    if (phoneNumber) {
        phoneNumber.forEach(function (el) {
            el.setAttribute('data-mask', '+7 000 000 00 00');
            el.addEventListener('input', function (event) {
                showMask(event);
            });
        });
    }

    function showMask(event) {
        const digitalPattern = /[0-9]/;
        const literalPattern = /[0\*]/;
        let input = event.target;
        let value = input.value;
        let mask = input.dataset.mask;
        let newValue = '';

        try {
            let maskLength = mask.length;
            let valueIndex = 0;
            let maskIndex = 0;

            for (; maskIndex < maskLength;) {
                if (maskIndex >= value.length) {
                    break;
                }

                if (mask[maskIndex] === '0' && value[valueIndex].match(digitalPattern) === null) {
                    break;
                }

                while (mask[maskIndex].match(literalPattern) === null) {
                    if (value[valueIndex] === mask[maskIndex]) {
                        break;
                    }

                    newValue += mask[maskIndex++];
                } //end cycle while

                newValue += value[valueIndex++];
                maskIndex++;
            } //end cycle for

            input.value = newValue;
        } catch (evt) {
            phoneNumber.removeEventListener();
        }
    }
}
maskedPhoneNumbers();


